
import { mapGetters } from "vuex"

export default {
  props: {
    code: { type: String, required: true },
    isBreak: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters("user", ["user"]),

    telemetry () {
      const { user } = this
      return {
        login: user ? user.id : "anonymous",
        registration: JSON.stringify({
          email: user ? user.email : "anonymous@startupvillage.ru",
        }),
      }
    },

    isBreak_: {
      get () { return this.isBreak },
      set (isBreak) { this.$emit("update:isBreak", isBreak) },
    },
  },

  mounted () {
    this.createPlayer()
    window.addEventListener("message", this.handlePlayerMessage)
  },
  beforeDestroy () {
    window.removeEventListener("message", this.handlePlayerMessage)
  },

  methods: {
    createPlayer () {
      const { player } = this.$refs
      if (!player) return
      window.comdiLoader?.createPlayer(player)
    },

    async handlePlayerMessage (event) {
      let { data } = event
      if (!data) return
      try { data = JSON.parse(data) } catch { return }
      if (!data) return

      const { currentEpisode } = data
      if (currentEpisode !== undefined) {
        console.log("-----EPISODE------", currentEpisode)
        if (currentEpisode === null) this.isBreak_ = true
        else {
          const code = currentEpisode.key
          if (code && code !== this.code) {
            const [res, err] = await this.$api.program.getSessionByCode(code)
            if (err) return console.error(err)
            if (!res) return
            this.$emit("session-change", res)
            this.isBreak_ = false
          }
        }
      }

      const time = data.currentTime?.absoluteTime ?? 0
      if (time) this.isBreak_ = false
    },
  },
}
