// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uD\\+7Y6LJ{gap:4rem}@media(max-width:991.98px){.uD\\+7Y6LJ{gap:3rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "uD+7Y6LJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
