
export default {
  props: {
    sessionAlias: { type: String, required: true },
    isBreak: { type: Boolean, required: true },
  },

  data: () => ({
    // Независимая от live сессия
    session: null,
  }),

  computed: {
    dislikes () { return this.session?.votes.dislikes ?? 0 },
    likes () { return this.session?.votes.likes ?? 0 },
    isDisliked () { return this.session?.voteValue === -1 },
    isLiked () { return this.session?.voteValue === 1 },
    isVoted () { return this.isLiked || this.isDisliked },
  },

  watch: {
    async sessionAlias () { await this.fetchSession() },
  },

  async mounted () { await this.fetchSession() },

  methods: {
    async fetchSession () {
      const [res, err] = await this.$api.program.getSession(this.sessionAlias)
      if (err) console.error(err)
      else this.session = res
    },

    async vote (value) {
      if (!this.$auth.loggedIn)
        return this.$router.push(this.localePath("/login"))
      if (this.isVoted) return
      const [, err] =
        await this.$api.program.addSessionVote(this.sessionAlias, value)
      if (err) console.error(err)
      else await this.fetchSession()
    },
  },
}
