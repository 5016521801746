
import capitalize from "lodash/capitalize"

export default {
  props: {
    sessionAlias: { type: String, required: true },
  },

  data: () => ({
    participants: [],
  }),

  computed: {
    speakers () {
      return this.participants.filter(p => p.speaker).map(p => p.person)
    },
    moderators () {
      return this.participants.filter(p => p.moderator).map(p => p.person)
    },
    experts () {
      return this.participants.filter(p => p.expert).map(p => p.person)
    },
  },

  watch: {
    async sessionAlias () {
      await this.fetchParticipants()
    },
  },

  async mounted () {
    await this.fetchParticipants()
  },

  methods: {
    async fetchParticipants () {
      const { sessionAlias } = this
      if (!sessionAlias) return
      const [res, err] = await this.$api.program.getParticipants({
        params: {
          "filter[session]": sessionAlias,
          [`sort[lastName${capitalize(this.$i18n.locale)}]`]: "asc",
        },
      })
      if (err) return console.error(err)
      this.participants = this.$api.helpers.getList(res)
    },

    getEnding (items) {
      return items.length > 1 ? "s" : ""
    },
  },
}
