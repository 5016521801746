
const urlPatterns = {
  vk: "https://vk.com/share.php?url={url}",
  telegram: "https://t.me/share/url?url={url}",
  // facebook: "https://www.facebook.com/sharer/sharer.php?u={url}",
  // twitter: "https://twitter.com/share?url={url}",
}

export default {
  props: {
    url: { type: String, default: "" },
  },

  computed: {
    urls () {
      const url = this.url || this.getCurrentUrl()
      return Object.fromEntries(Object.entries(urlPatterns)
        .map(([social, pattern]) => [social, pattern.replace("{url}", url)]))
    },
  },

  async mounted () {
    await import("bootstrap/js/dist/dropdown")
  },

  methods: {
    getCurrentUrl () {
      return encodeURIComponent(window.location)
    },
  },
}
