// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c6GRnLTG{font-size:2rem;letter-spacing:.02em;line-height:1.4;margin-bottom:1.5em}@media(max-width:991.98px){.c6GRnLTG{font-size:1.5rem}}.o1yjscnD{--cols:4;grid-gap:2.5rem;display:grid;gap:2.5rem;grid-template-columns:repeat(4,1fr);grid-template-columns:repeat(var(--cols),1fr)}@media(max-width:1365.98px){.o1yjscnD{--cols:3}}@media(max-width:991.98px){.o1yjscnD{--cols:2}}@media(max-width:575.98px){.o1yjscnD{display:none}}.yCjrODFR{display:none}@media(max-width:575.98px){.yCjrODFR{display:flex}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "c6GRnLTG",
	"grid": "o1yjscnD",
	"swiper": "yCjrODFR"
};
module.exports = ___CSS_LOADER_EXPORT___;
