// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".svs4oOr5{gap:1.63rem}.JnqfR7Ic{align-items:center;background:none;color:inherit;display:flex;font-size:.81rem;gap:.88rem;line-height:1.4;padding:0}.JnqfR7Ic:not(:disabled){cursor:pointer}@media(hover:hover){.JnqfR7Ic:not(:disabled):hover{opacity:.8}}@media(hover:none){.JnqfR7Ic:not(:disabled):active{opacity:.8}}.JnqfR7Ic:not(:disabled):active{opacity:.7}.JnqfR7Ic:disabled{cursor:default}.WD\\+tj7A1{color:#b1ec52}.JnqfR7Ic svg{height:1rem;width:1rem}.JnqfR7Ic svg>*{fill:currentColor}.SqTAYJnl{transform:scale(-1)}.dycdKUYT{gap:max(.56rem,min(.83vw,1rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"votes": "svs4oOr5",
	"vote": "JnqfR7Ic",
	"voteActive": "WD+tj7A1",
	"dislike": "SqTAYJnl",
	"btns": "dycdKUYT"
};
module.exports = ___CSS_LOADER_EXPORT___;
