// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fE50yDS9{margin-bottom:8.75rem}@media(max-width:1365.98px){.fE50yDS9{margin-bottom:6.25rem}}@media(max-width:991.98px){.fE50yDS9{margin-bottom:5rem}}@media(max-width:575.98px){.fE50yDS9{margin-bottom:3.13rem}}.HT8s9-sd{margin:0 auto;max-width:100%;width:82.88rem}.PxH4Uwa3{border-radius:.5rem;margin-bottom:max(1rem,min(1.25vw,1.5rem))}.hXg963wM{width:100%}.hXg963wM,.hXg963wM>*{border-radius:inherit}.hXg963wM>*{width:100%!important}.fRe4NMnl,.hXg963wM>*{background:rgba(0,0,0,.2)}.fRe4NMnl{aspect-ratio:16/9}.PmN048G\\+{font-size:max(1rem,min(.94vw,1.13rem));font-weight:600;line-height:1.4;margin-top:max(1.5rem,min(3.75vw,4.5rem))}.Rvbz4aPo{margin-top:max(2.25rem,min(3.33vw,4rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "fE50yDS9",
	"inner": "HT8s9-sd",
	"playerContainer": "PxH4Uwa3",
	"player": "hXg963wM",
	"playerPlaceholder": "fRe4NMnl",
	"description": "PmN048G+",
	"participants": "Rvbz4aPo"
};
module.exports = ___CSS_LOADER_EXPORT___;
