import { render, staticRenderFns } from "./LiveStreamActions.vue?vue&type=template&id=7a90a17d"
import script from "./LiveStreamActions.vue?vue&type=script&lang=js"
export * from "./LiveStreamActions.vue?vue&type=script&lang=js"
import style0 from "./LiveStreamActions.vue?vue&type=style&index=0&id=7a90a17d&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppIcon: require('/app/components/app/AppIcon.vue').default,AppLocaleSwitch: require('/app/components/app/AppLocaleSwitch.vue').default,AppShare: require('/app/components/app/AppShare.vue').default})
