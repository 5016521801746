// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sdQuntJK{gap:13rem;margin-bottom:max(1.5rem,min(3.75vw,4.5rem))}@media(max-width:1365.98px){.sdQuntJK{gap:2rem}}@media(max-width:991.98px){.sdQuntJK{flex-direction:column}}@media(max-width:575.98px){.sdQuntJK{gap:1rem}}.uvNULHqi{font-size:max(1.13rem,min(1.46vw,1.75rem));font-weight:600;line-height:1.2;margin-bottom:.5rem}.s81HOWee{display:flex;flex-direction:column;gap:max(.28rem,min(.42vw,.5rem))}@media(max-width:991.98px){.s81HOWee{flex-direction:row;flex-wrap:wrap}}.hxqUh-jO{font-size:.81rem}.G8scBZ\\+Q{align-items:center;font-size:1rem;gap:1.25em}@media(max-width:991.98px){.G8scBZ\\+Q{font-size:.88rem}}@media(max-width:575.98px){.G8scBZ\\+Q{font-size:.75rem}}.T4SzmNTG{font-size:max(.88rem,min(.94vw,1.13rem));line-height:1.6;white-space:nowrap}._3kCAnjPR{color:#b1ec52}.y72FJnXA{font-feature-settings:\"tnum\";font-variant-numeric:tabular-nums}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "sdQuntJK",
	"title": "uvNULHqi",
	"btns": "s81HOWee",
	"programBtn": "hxqUh-jO",
	"labels": "G8scBZ+Q",
	"label": "T4SzmNTG",
	"labelPrimary": "_3kCAnjPR",
	"timer": "y72FJnXA"
};
module.exports = ___CSS_LOADER_EXPORT___;
