
import breakpoints from "@/assets/style/resources/breakpoints/module.scss"

export default {
  props: {
    participants: { type: Array, required: true },
    title: { type: String, required: true },
  },

  data: () => ({
    swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 40,
      breakpoints: {
        [breakpoints.sm]: { slidesPerView: 2 },
        [breakpoints.lg]: { slidesPerView: 3 },
        [breakpoints.xl]: { slidesPerView: 4 },
      },
    },
  }),
}
