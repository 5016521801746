// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._95kmECwY{background:#1a153f;border-radius:.5rem;min-width:0;min-width:auto;overflow:hidden;padding:0;width:2.44rem}._9oGjuUrU{color:#fff;padding:.38rem}._9oGjuUrU:hover{background:#221b52;color:#fff}._9oGjuUrU:active{background:#120f2c}._9oGjuUrU svg>*{fill:currentColor}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"links": "_95kmECwY",
	"link": "_9oGjuUrU"
};
module.exports = ___CSS_LOADER_EXPORT___;
