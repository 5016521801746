
export default {
  props: {
    track: { type: Object, default: null },
    // Сессия из страницы с записью
    session: { type: Object, default: null },
    withFullDate: { type: Boolean, default: false },
    withParticipants: { type: Boolean, default: false },
  },

  data: () => ({
    liveSession: null,
    isEmbedScriptLoaded: false,
    isBreak: false,
  }),

  async fetch () {
    await this.fetchLiveSession()
  },

  head () {
    return {
      script: [{
        hid: "embed-script",
        src: this.$config.streamEmbedScript,
        defer: true,
        callback: () => { this.isEmbedScriptLoaded = true },
      }],
    }
  },

  computed: {
    session_ () {
      return this.liveSession || this.session
    },

    embedCode () {
      // Запись
      const { session } = this
      if (session) return session.liveData?.embedCode
      // Лайв
      return this.track?.liveData?.embedCode
    },
  },

  watch: {
    async track () {
      await this.fetchLiveSession()
    },
    // embedCode: {
    //   handler (code) {
    //     console.log("CODE", code, this.track)
    //   },
    //   immediate: true,
    // },
  },

  methods: {
    async fetchLiveSession () {
      const hallAlias = this.track?.hall?.alias
      if (!hallAlias) return
      const [res, err] =
        await this.$api.program.getHallLiveSession(hallAlias)
      if (err) console.error(err)
      else this.liveSession = res
    },
  },
}
